import styled, { createGlobalStyle } from 'styled-components'
import { fontClamp } from './helpers'
import { motion } from 'framer-motion'

const breakpoints = {
  TABLET: '@media (min-width: 768px)',
  DESKTOP: '@media (min-width: 1200px)',
}

export const GlobalStyle = createGlobalStyle`
  html,
  body,
  #___gatsby,
  #gatsby-focus-wrapper {
    background-color: #1d252d;
    color: #f5f5f5;
    margin: 0;
    font-family: sans-serif;
  }
  
  * {
    box-sizing: border-box;
  }
`

export const Nav = styled.nav`
  position: relative;
  width: 100%;
  height: auto;
  max-width: 1200px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${breakpoints.TABLET} {
    height: 100px;
  }

  & a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-decoration: none;
  }

  & button {
    background-color: white;
    color: black;
    width: 150px;
    border-radius: 0;

    ${breakpoints.TABLET} {
      position: absolute;
      right: 20px;
      top: 20px;
      margin: 0;
    }
  }
`

export const Logo = styled(motion.img)`
  display: block;
  margin-top: 25px;
  padding: 0 36px;
  width: 100%;
  height: auto;

  ${breakpoints.TABLET} {
    position: absolute;
    margin-top: 0;
    height: 50px;
    width: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
  }
`

export const Header = styled.h1`
  font-family: 'Play', sans-serif;
  text-align: center;
  color: white;
  font-size: ${fontClamp(36, 50)};
  margin-bottom: 0;

  & span {
    color: #f3cd59;
  }
`

export const Main = styled.main`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 100px;
  
`

export const VideoContainer = styled.div`
  position: relative;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
`

export const VideoContainerFull = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
`

export const Video = styled.div`
  position: relative;
  padding-top: 56.25%;

  & .react-player {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
`

export const AboutText = styled.p`
  font-size: 22px;
  margin: 56px 35px;
  line-height: 1.35;

  ${breakpoints.DESKTOP} {
    margin: 56px 0;
  }
`

export const TestimonialGrid = styled.div`
  display: block;
  margin: 50px 36px;

  & > div {
    margin-top: 56px;
    margin-bottom: 56px;
  }

  ${breakpoints.TABLET} {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    & > div {
      margin: 0;
    }

    & > div:first-of-type {
      margin-right: 75px;
    }

    & > div:last-of-type {
      margin-left: 75px;
    }
  }

  ${breakpoints.DESKTOP} {
    margin: 100px 0;
  }
`

export const AwardImageList = styled.ul`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 56px 35px;
  padding: 0;
`

export const AwardImageContainer = styled.li`
  list-style: none;
  display: inline-block;
  flex: 100%;
  max-width: 100%;

  ${breakpoints.TABLET} {
    flex: 20%;
    max-width: 20%;
  }
`

export const ScheduleLink = styled.div`
  display: block;
  text-decoration: none;
  background-color: #f3cd59;
  color: white;
  cursor: pointer;
  font-size: 20px;
  line-height: 50px;
  text-align: center;
  height: 50px;
  padding: 0 33px;
  border-radius: 4px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 250px;
  margin: 50px auto;
  border: none;
`

export const AwardImage = styled(motion.img)`
  object-fit: contain;
  width: 100%;
`

export const Testimonial = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
`

export const TestimonialImg = styled.img`
  width: 100%;
  height: auto;
`

export const TestimonialP = styled.p``

export const TestimonialBy = styled.p``

export const SideBySide = styled.div`
  display: block;
  margin-top: 50px;

  ${breakpoints.DESKTOP} {
    margin-top: 100px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
`
export const SBSRight = styled.div`
  position: relative;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  padding: 0 36px;
  ${breakpoints.DESKTOP} {
    margin: 0;
  }
`

export const SBSTitle = styled.h2`
  display: flex;
  justify-content: center;

  ${breakpoints.DESKTOP} {
    margin: 0;
  }
`

export const BenefitList = styled.ul`
  padding: 0;
`

export const BenefitListItem = styled.li`
  list-style: none;
  margin-bottom: 10px;
`
export const ScheduleTitle = styled.h2`
  display: flex;
  justify-content: center;
  margin-top: 100px;
`

export const SuccessMessage = styled.div`
  position: absolute;
  border-radius: 20px;
  background-color: #2b3743;
  padding: 36px;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  font-weight: 600;
  font-size: 35px;
  text-align: center;

  & > div {
    position: relative;
  }

  & a {
    text-decoration: none;
  }

  & img {
    position: relative;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 25px;
  }
`

export const FormContainer = styled.div`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
`

export const CarouselImage = styled.img`
  display: none !important;
`;

export const CarouselContainer = styled.div`
  margin-top: 50px;
`

export const CarouselControls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
  .btn {
    margin: 10px;
    background-color: white;
    color: black;
    border: none;
    border-radius: 2px;
    padding: 5px 10px;
    text-transform: uppercase;
    font-weight: bold;
  }
`